













import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { MyPageViewModel } from '../../../viewmodels/my-page-viewmodel'

@Observer
@Component({
  components: {
    observer: () => import('vue-intersection-observer'),
  },
})
export default class extends Vue {
  @Inject() vm!: MyPageViewModel

  loadMore(entry) {
    if (this.vm.topPostsCanLoadMore) {
      if (entry.isIntersecting) this.vm.topPostsLoadMore()
    }
  }
}
